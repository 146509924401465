/* Base */
html {
    scroll-behavior: smooth;
}
/* Defaults */
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: #e5e5e5;
}
/* lazysizes auto width calculation */
picture img[data-sizes='auto'] {
    display: block;
    width: 100%;
}
picture img.w-auto[data-sizes='auto'] {
    width: auto;
}
/* Hide AlpineJS components until Alpine is ready */
html:not(.tw-has-no-js) [x-cloak] {
    display: none;
}
*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
/* @import 'tailwindcss/components'; */
/* Custom Components */
/* Fluid embed iframes */
/* Lazyloading images etc. */
.lazyload,
.lazyloading {
    transition: all 0.3s;
    opacity: 0.3;
    background: transparent no-repeat scroll center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 25%;
}
.lazyloaded {
    transition: all 0.3s;
    opacity: 1;
    background-image: none;
}
a.glossary-inline-link {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 1.5px;
    text-decoration-style: dotted;
    text-decoration-color: hsl(15, 68%, 62%);
    cursor: help;
    transition: all 0.1s ease-in-out;
    outline: 0 solid hsla(15, 68%, 62%, 85%);
}
a.glossary-inline-link:hover {
        background-color: hsla(15, 68%, 62%, 85%);
        outline-width: 0.2em;
        text-decoration-color: hsla(15, 68%, 62%, 0%);
        border-radius: 2px;
    }
/* button internals */
.button-internal-icon {
    width: 1rem;
    height: 1rem;
    stroke-width: 2;
}
.button-internal-icon[data-position='end'] {
    order: 9999;
}
/* Navigation */
.siteNav:hover button:not(:hover) {
  opacity: 0.7;
}
.rte > *:last-child {
    margin-bottom: 0px;
}
.rte ol,
    .rte ul {
    padding-left: 1rem;
}
.rte li:before {
    margin-left: -1rem;
    display: inline-block;
    width: 1rem;
}
.rte li + li {
    margin-top: 0.5rem;
}
.rte li li {
    margin-left: 0.5rem;
}
.rte ol li:before,
    .rte ul[style] li:before {
    --tw-content: none;
    content: var(--tw-content);
}
.rte blockquote {
    margin-left: 0px;
    margin-right: 0px;
    border-left-width: 4px;
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity));
    padding-left: 1rem;
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
    font-style: italic;
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity));
}
@media (min-width: 60em) {
    .rte blockquote {
        margin-right: 2rem;
        padding-left: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}
.rte h3,
    .rte h4,
    .rte h5,
    .rte h6 {
    line-height: 1rem;
}
@media (min-width: 60em) {
    .rte h3,
    .rte h4,
    .rte h5,
    .rte h6 {
        line-height: 1.375;
    }
}
.rte h2 {
    font-size: 1.5rem;
    line-height: 2rem;
}
@media (min-width: 60em) {
    .rte h2 {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
}
.rte h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
@media (min-width: 60em) {
    .rte h3 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
.rte h4 {
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
}
@media (min-width: 60em) {
    .rte h4 {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
.rte h5 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
}
@media (min-width: 60em) {
    .rte h5 {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
.rte h6 {
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
}
@media (min-width: 60em) {
    .rte h6 {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}
.rte img {
    height: auto;
    max-width: 100%;
}
.rte figure {
      display: table;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
.rte figcaption p {
    margin: 0px;
}
.toc li:before {
    position: absolute;
    left: 1.5rem;
    top: 0.25rem;
    padding-right: 0.25rem;
    --tw-text-opacity: 1;
    color: rgb(207 111 79 / var(--tw-text-opacity));
}
.toc a, .toc a:visited {
    --tw-text-opacity: 1;
    color: rgb(117 117 117 / var(--tw-text-opacity));
}
.meta-m2 {
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
/* Text Utilities */
.case-inherit {
  text-transform: inherit;
}
.text-align-inherit {
  text-align: inherit;
}
/* Heading Anchors */
:is(h2) a.anchor {
    position: absolute;
  width: 1.25em;
  height: 1.25em;
  margin-left: 0.25em;
  border-radius: 9999px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244' /%3E%3C/svg%3E");
  background-size: 60% auto;
}
:is(h2) a.anchor.copied {
    opacity: 1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3.5' stroke='green'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E");
}
:is(h2) a.anchor.copied.fadeOut {
    opacity: 0;
}
:is(h2) a.anchor.copied::after {
      position: absolute;
      text-align: center;
      width: 150%;
      left: -25%;
      top: 115%;
  }
@media (min-width: 60em) {
    :is(h2) a.anchor.copied::after {
        top: 50%;
        left: 100%;
        width: auto;
        --tw-translate-y: -50%;
        --tw-translate-x: 0.75em;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
}
:is(h2) a.anchor.copied::after {
      opacity: 0.5;
      content: ' Copied';
      font-size: 0.5em;
  }
:is(h2):hover a.anchor {
    opacity: 0.5;
}
:is(h2):hover a.anchor:hover {
    opacity: 1;
  outline-color: rgba(0, 0, 0, 0.1);
  outline-width: 4px;
  text-decoration-color: rgba(51, 46, 25, 0);
  background-color: rgba(0, 0, 0, 0.1);
}
/* 
    Reel Component
    Scroll content horizontally when overflowing.
    https://every-layout.dev/layouts/reel/
*/
.reel {
    --gutter: 0.5rem;
    --scrollbar-bg: transparent;
    --scrollbar-color: #EF9C80;
    
    display: flex;
    block-size: auto;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg);
}
.reel::-webkit-scrollbar {
    block-size: 0.25rem;
}
.reel::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg);
}
.reel::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-bg);
    background-image: linear-gradient(
        var(--scrollbar-bg) 0,
        var(--scrollbar-bg) 0.25rem,
        var(--scrollbar-color) 0.25rem,
        var(--scrollbar-color) 0.75rem,
        var(--scrollbar-bg) 0.75rem);
}
.reel::after {
    content: '';
    flex-basis: 1rem;
    flex-shrink: 0;
}
.reel > * {
    flex: 0 0 auto;
}
.reel > * + * {
    margin-inline-start: var(--gutter);
}
.reel.overflowing {
    padding-block-end: var(--gutter);
}
.reel.reel-compact.overflowing {
    padding-block-end: 0;
}
.tw-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.tw-fixed {
    position: fixed;
}
.tw-absolute {
    position: absolute;
}
.tw-relative {
    position: relative;
}
.tw-sticky {
    position: sticky;
}
.tw-inset-0 {
    inset: 0px;
}
.tw-inset-x-0 {
    left: 0px;
    right: 0px;
}
.tw-inset-y-0 {
    top: 0px;
    bottom: 0px;
}
.tw-bottom-\[10\%\] {
    bottom: 10%;
}
.tw-left-0 {
    left: 0px;
}
.tw-right-0 {
    right: 0px;
}
.tw-top-0 {
    top: 0px;
}
.tw-z-20 {
    z-index: 20;
}
.tw-z-30 {
    z-index: 30;
}
.tw-z-50 {
    z-index: 50;
}
.tw-z-\[110\] {
    z-index: 110;
}
.tw-z-\[50\] {
    z-index: 50;
}
.tw-z-\[999\] {
    z-index: 999;
}
.tw-order-first {
    order: -9999;
}
.tw-col-span-1 {
    grid-column: span 1 / span 1;
}
.tw-col-span-full {
    grid-column: 1 / -1;
}
.tw-m-0 {
    margin: 0px;
}
.tw-m-3 {
    margin: 0.75rem;
}
.-tw-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
}
.tw-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.tw-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.-tw-mb-px {
    margin-bottom: -1px;
}
.-tw-mr-1 {
    margin-right: -0.25rem;
}
.-tw-mr-4 {
    margin-right: -1rem;
}
.-tw-mt-6 {
    margin-top: -1.5rem;
}
.tw-mb-0 {
    margin-bottom: 0px;
}
.tw-mb-16 {
    margin-bottom: 4rem;
}
.tw-mb-2 {
    margin-bottom: 0.5rem;
}
.tw-mb-32 {
    margin-bottom: 8rem;
}
.tw-mb-4 {
    margin-bottom: 1rem;
}
.tw-mb-8 {
    margin-bottom: 2rem;
}
.tw-ml-1 {
    margin-left: 0.25rem;
}
.tw-ml-auto {
    margin-left: auto;
}
.tw-mt-1 {
    margin-top: 0.25rem;
}
.tw-mt-12 {
    margin-top: 3rem;
}
.tw-mt-2 {
    margin-top: 0.5rem;
}
.tw-mt-4 {
    margin-top: 1rem;
}
.tw-mt-6 {
    margin-top: 1.5rem;
}
.tw-block {
    display: block;
}
.tw-inline-block {
    display: inline-block;
}
.tw-flex {
    display: flex;
}
.tw-inline-flex {
    display: inline-flex;
}
.tw-grid {
    display: grid;
}
.tw-contents {
    display: contents;
}
.tw-hidden {
    display: none;
}
.tw-aspect-square {
    aspect-ratio: 1 / 1;
}
.tw-h-1\.5 {
    height: 0.375rem;
}
.tw-h-10 {
    height: 2.5rem;
}
.tw-h-3 {
    height: 0.75rem;
}
.tw-h-4 {
    height: 1rem;
}
.tw-h-6 {
    height: 1.5rem;
}
.tw-h-auto {
    height: auto;
}
.tw-h-full {
    height: 100%;
}
.tw-max-h-96 {
    max-height: 24rem;
}
.tw-w-1\/3 {
    width: 33.333333%;
}
.tw-w-10 {
    width: 2.5rem;
}
.tw-w-24 {
    width: 6rem;
}
.tw-w-3 {
    width: 0.75rem;
}
.tw-w-4 {
    width: 1rem;
}
.tw-w-5 {
    width: 1.25rem;
}
.tw-w-56 {
    width: 14rem;
}
.tw-w-6 {
    width: 1.5rem;
}
.tw-w-auto {
    width: auto;
}
.tw-w-full {
    width: 100%;
}
.tw-min-w-48 {
    min-width: 12rem;
}
.tw-min-w-6 {
    min-width: 1.5rem;
}
.tw-max-w-md {
    max-width: 28rem;
}
.tw-flex-shrink-0 {
    flex-shrink: 0;
}
.tw-shrink-0 {
    flex-shrink: 0;
}
.tw-grow {
    flex-grow: 1;
}
.-tw-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-tw-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-\[0\.15em\] {
    --tw-translate-y: 0.15em;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-\[100vh\] {
    --tw-translate-y: 100vh;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-translate-y-px {
    --tw-translate-y: 1px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-cursor-pointer {
    cursor: pointer;
}
.tw-scroll-mt-6 {
    scroll-margin-top: 1.5rem;
}
.tw-appearance-none {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.tw-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
.tw-flex-row {
    flex-direction: row;
}
.tw-flex-col {
    flex-direction: column;
}
.tw-flex-col-reverse {
    flex-direction: column-reverse;
}
.tw-flex-wrap {
    flex-wrap: wrap;
}
.tw-items-center {
    align-items: center;
}
.tw-justify-end {
    justify-content: flex-end;
}
.tw-justify-center {
    justify-content: center;
}
.tw-justify-between {
    justify-content: space-between;
}
.tw-justify-evenly {
    justify-content: space-evenly;
}
.tw-justify-items-center {
    justify-items: center;
}
.tw-gap-1 {
    gap: 0.25rem;
}
.tw-gap-2 {
    gap: 0.5rem;
}
.tw-gap-4 {
    gap: 1rem;
}
.tw-gap-legacy {
    gap: 34px;
}
.tw-gap-x-0\.5 {
    -moz-column-gap: 0.125rem;
         column-gap: 0.125rem;
}
.tw-gap-x-1 {
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}
.tw-gap-x-2 {
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
}
.tw-gap-x-4 {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}
.tw-gap-x-6 {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
}
.tw-gap-x-\[0\.6rem\] {
    -moz-column-gap: 0.6rem;
         column-gap: 0.6rem;
}
.tw-gap-x-legacy {
    -moz-column-gap: 34px;
         column-gap: 34px;
}
.tw-gap-y-1 {
    row-gap: 0.25rem;
}
.tw-gap-y-10 {
    row-gap: 2.5rem;
}
.tw-gap-y-16 {
    row-gap: 4rem;
}
.tw-gap-y-2 {
    row-gap: 0.5rem;
}
.tw-gap-y-3 {
    row-gap: 0.75rem;
}
.tw-gap-y-4 {
    row-gap: 1rem;
}
.tw-gap-y-5 {
    row-gap: 1.25rem;
}
.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.tw-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}
.tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.tw-overflow-auto {
    overflow: auto;
}
.tw-overflow-hidden {
    overflow: hidden;
}
.tw-overflow-y-auto {
    overflow-y: auto;
}
.tw-overflow-x-hidden {
    overflow-x: hidden;
}
.tw-text-balance {
    text-wrap: balance;
}
.tw-rounded {
    border-radius: 0.25rem;
}
.tw-rounded-full {
    border-radius: 9999px;
}
.tw-rounded-lg {
    border-radius: 0.5rem;
}
.tw-rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
.tw-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.tw-rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
}
.tw-rounded-ss-none {
    border-start-start-radius: 0px;
}
.tw-rounded-tr-none {
    border-top-right-radius: 0px;
}
.tw-border {
    border-width: 1px;
}
.tw-border-0 {
    border-width: 0px;
}
.tw-border-2 {
    border-width: 2px;
}
.tw-border-b {
    border-bottom-width: 1px;
}
.tw-border-b-0 {
    border-bottom-width: 0px;
}
.tw-border-t {
    border-top-width: 1px;
}
.tw-border-t-0 {
    border-top-width: 0px;
}
.tw-border-solid {
    border-style: solid;
}
.tw-border-none {
    border-style: none;
}
.tw-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(212 212 212 / var(--tw-border-opacity));
}
.tw-border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity));
}
.tw-border-salmon {
    --tw-border-opacity: 1;
    border-color: rgb(207 111 79 / var(--tw-border-opacity));
}
.tw-bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.tw-bg-black\/\[\.05\] {
    background-color: rgb(0 0 0 / .05);
}
.tw-bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.tw-bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}
.tw-bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}
.tw-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}
.tw-bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}
.tw-bg-inherit {
    background-color: inherit;
}
.tw-bg-salmon {
    --tw-bg-opacity: 1;
    background-color: rgb(207 111 79 / var(--tw-bg-opacity));
}
.tw-bg-salmon-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(207 111 79 / var(--tw-bg-opacity));
}
.tw-bg-transparent {
    background-color: transparent;
}
.tw-bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.tw-bg-opacity-10 {
    --tw-bg-opacity: 0.1;
}
.tw-bg-opacity-40 {
    --tw-bg-opacity: 0.4;
}
.tw-stroke-gray-500 {
    stroke: #737373;
}
.tw-stroke-2 {
    stroke-width: 2;
}
.tw-object-cover {
    -o-object-fit: cover;
       object-fit: cover;
}
.tw-p-1 {
    padding: 0.25rem;
}
.tw-p-1\.5 {
    padding: 0.375rem;
}
.tw-p-2 {
    padding: 0.5rem;
}
.tw-p-3 {
    padding: 0.75rem;
}
.tw-p-4 {
    padding: 1rem;
}
.tw-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.tw-px-\[0\.2rem\] {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}
.tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.tw-py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.tw-py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.tw-py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}
.tw-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.tw-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.tw-pb-5 {
    padding-bottom: 1.25rem;
}
.tw-pl-10 {
    padding-left: 2.5rem;
}
.tw-pl-2 {
    padding-left: 0.5rem;
}
.tw-pl-3 {
    padding-left: 0.75rem;
}
.tw-pl-6 {
    padding-left: 1.5rem;
}
.tw-pr-0 {
    padding-right: 0px;
}
.tw-pr-3 {
    padding-right: 0.75rem;
}
.tw-pr-6 {
    padding-right: 1.5rem;
}
.tw-pt-1 {
    padding-top: 0.25rem;
}
.tw-pt-11 {
    padding-top: 2.75rem;
}
.tw-pt-4 {
    padding-top: 1rem;
}
.tw-text-center {
    text-align: center;
}
.tw-text-right {
    text-align: right;
}
.tw-font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
}
.tw-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.tw-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.tw-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.tw-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.tw-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.tw-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.tw-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.tw-font-bold {
    font-weight: 700;
}
.tw-font-normal {
    font-weight: 400;
}
.tw-font-semibold {
    font-weight: 600;
}
.tw-uppercase {
    text-transform: uppercase;
}
.tw-italic {
    font-style: italic;
}
.tw-leading-none {
    line-height: 1;
}
.tw-tracking-wide {
    letter-spacing: 0.025em;
}
.tw-text-gray-350 {
    --tw-text-opacity: 1;
    color: rgb(117 117 117 / var(--tw-text-opacity));
}
.tw-text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(163 163 163 / var(--tw-text-opacity));
}
.tw-text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 82 / var(--tw-text-opacity));
}
.tw-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity));
}
.tw-text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(23 23 23 / var(--tw-text-opacity));
}
.tw-text-inherit {
    color: inherit;
}
.tw-text-salmon-500 {
    --tw-text-opacity: 1;
    color: rgb(207 111 79 / var(--tw-text-opacity));
}
.tw-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.tw-opacity-0 {
    opacity: 0;
}
.tw-opacity-100 {
    opacity: 1;
}
.tw-opacity-40 {
    opacity: 0.4;
}
.tw-opacity-75 {
    opacity: 0.75;
}
.tw-shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.tw-outline-1 {
    outline-width: 1px;
}
.tw-outline-offset-0 {
    outline-offset: 0px;
}
.tw-outline-white {
    outline-color: #fff;
}
.tw-grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.tw-backdrop-blur {
    --tw-backdrop-blur: blur(8px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.tw-transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.tw-duration-150 {
    transition-duration: 150ms;
}
.tw-ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.tw-ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
/* @import 'utilities'; */
/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */
.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;
  

  --pswp-root-z-index: 100000;
  
  --pswp-preloader-color: rgba(79, 79, 79, 0.4);
  --pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);
  
  /* defined via js:
  --pswp-transition-duration: 333ms; */
  
  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;

  --pswp-error-text-color: var(--pswp-icon-color);
}
/*
	Styles for basic PhotoSwipe (pswp) functionality (sliding area, open/close transitions)
*/
.pswp {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--pswp-root-z-index);
	display: none;
	touch-action: none;
	outline: 0;
	opacity: 0.003;
	contain: layout style size;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* Prevents focus outline on the root element,
  (it may be focused initially) */
.pswp:focus {
  outline: 0;
}
.pswp * {
  box-sizing: border-box;
}
.pswp img {
  max-width: none;
}
.pswp--open {
	display: block;
}
.pswp,
.pswp__bg {
	transform: translateZ(0);
	will-change: opacity;
}
.pswp__bg {
  opacity: 0.005;
	background: var(--pswp-bg);
}
.pswp,
.pswp__scroll-wrap {
	overflow: hidden;
}
.pswp__scroll-wrap,
.pswp__bg,
.pswp__container,
.pswp__item,
.pswp__content,
.pswp__img,
.pswp__zoom-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.pswp__img,
.pswp__zoom-wrap {
	width: auto;
	height: auto;
}
.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
	cursor: zoom-in;
}
.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
	cursor: move;
	cursor: grab;
}
.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: grabbing;
}
/* :active to override grabbing cursor */
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
.pswp__img {
	cursor: zoom-out;
}
/* Prevent selection and tap highlights */
.pswp__container,
.pswp__img,
.pswp__button,
.pswp__counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.pswp__item {
	/* z-index for fade transition */
	z-index: 1;
	overflow: hidden;
}
.pswp__hidden {
	display: none !important;
}
/* Allow to click through pswp__content element, but not its children */
.pswp__content {
  pointer-events: none;
}
.pswp__content > * {
  pointer-events: auto;
}
/*

  PhotoSwipe UI

*/
/*
	Error message appears when image is not loaded
	(JS option errorMsg controls markup)
*/
.pswp__error-msg-container {
  display: grid;
}
.pswp__error-msg {
	margin: auto;
	font-size: 1em;
	line-height: 1;
	color: var(--pswp-error-text-color);
}
/*
class pswp__hide-on-close is applied to elements that
should hide (for example fade out) when PhotoSwipe is closed
and show (for example fade in) when PhotoSwipe is opened
 */
.pswp .pswp__hide-on-close {
	opacity: 0.005;
	will-change: opacity;
	transition: opacity var(--pswp-transition-duration) cubic-bezier(0.4, 0, 0.22, 1);
	z-index: 10; /* always overlap slide content */
	pointer-events: none; /* hidden elements should not be clickable */
}
/* class pswp--ui-visible is added when opening or closing transition starts */
.pswp--ui-visible .pswp__hide-on-close {
	opacity: 1;
	pointer-events: auto;
}
/* <button> styles, including css reset */
.pswp__button {
	position: relative;
	display: block;
	width: 50px;
	height: 60px;
	padding: 0;
	margin: 0;
	overflow: hidden;
	cursor: pointer;
	background: none;
	border: 0;
	box-shadow: none;
	opacity: 0.85;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
}
.pswp__button:hover,
.pswp__button:active,
.pswp__button:focus {
  transition: none;
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 1;
}
.pswp__button:disabled {
  opacity: 0.3;
  cursor: auto;
}
.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
  position: absolute;
  top: 14px;
  left: 9px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  pointer-events: none;
}
.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}
.pswp__icn:focus {
	outline: 0;
}
/*
	div element that matches size of large image,
	large image loads on top of it,
	used when msrc is not provided
*/
div.pswp__img--placeholder,
.pswp__img--with-bg {
	background: var(--pswp-placeholder-bg);
}
.pswp__top-bar {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 60px;
	display: flex;
  flex-direction: row;
  justify-content: flex-end;
	z-index: 10;

	/* allow events to pass through top bar itself */
	pointer-events: none !important;
}
.pswp__top-bar > * {
  pointer-events: auto;
  /* this makes transition significantly more smooth,
     even though inner elements are not animated */
  will-change: opacity;
}
/*

  Close button

*/
.pswp__button--close {
  margin-right: 6px;
}
/*

  Arrow buttons

*/
.pswp__button--arrow {
  position: absolute;
  top: 0;
  width: 75px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
}
.pswp__button--arrow:disabled {
  display: none;
  cursor: default;
}
.pswp__button--arrow .pswp__icn {
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: none;
  border-radius: 0;
}
.pswp--one-slide .pswp__button--arrow {
  display: none;
}
/* hide arrows on touch screens */
.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}
/* show arrows only after mouse was used */
.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}
.pswp__button--arrow--prev {
  right: auto;
  left: 0px;
}
.pswp__button--arrow--next {
  right: 0px;
}
.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  /* flip horizontally */
  transform: scale(-1, 1);
}
/*

  Zoom button

*/
.pswp__button--zoom {
  display: none;
}
.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}
/* "+" => "-" */
.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}
/*

  Loading indicator

*/
.pswp__preloader {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 60px;
  margin-right: auto;
}
.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity 0.2s linear;
  animation: pswp-clockwise 600ms linear infinite;
}
.pswp__preloader--active .pswp__icn {
  opacity: 0.85;
}
@keyframes pswp-clockwise {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/*

  "1 of 10" counter

*/
.pswp__counter {
  height: 30px;
  margin-top: 15px;
  margin-inline-start: 20px;
  font-size: 14px;
  line-height: 30px;
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: 0.85;
}
.pswp--one-slide .pswp__counter {
  display: none;
}
.pswp__dynamic-caption {
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  transition: opacity 120ms linear !important; /* override default */
}
.pswp-caption-content {
  display: none;
}
.pswp__dynamic-caption a {
  color: #fff;
}
.pswp__dynamic-caption--faded {
  opacity: 0 !important;
}
.pswp__dynamic-caption--aside {
  width: auto;
  max-width: 300px;
  padding: 20px 15px 20px 20px;
  margin-top: 70px;
}
.pswp__dynamic-caption--below {
  width: auto;
  max-width: 700px;
  padding: 15px 0 0;
}
.pswp__dynamic-caption--on-hor-edge {
  padding-left: 15px;
  padding-right: 15px;
}
.pswp__dynamic-caption--mobile {
  width: 100%;
  background: rgba(0,0,0,0.5);
  padding: 10px 15px;

  right: 0;
  bottom: 0;

  /* override styles that were set via JS.
    as they interfere with size measurement */
  top: auto !important; 
  left: 0 !important;
}
.visited\:tw-text-salmon-500:visited {
    color: rgb(207 111 79 );
}
.visited\:tw-text-white:visited {
    color: rgb(255 255 255 );
}
.hover\:tw-cursor-pointer:hover {
    cursor: pointer;
}
.hover\:tw-border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgb(163 163 163 / var(--tw-border-opacity));
}
.hover\:tw-bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}
.hover\:tw-bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}
.hover\:tw-bg-salmon-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 125 92 / var(--tw-bg-opacity));
}
.hover\:tw-bg-transparent:hover {
    background-color: transparent;
}
.hover\:tw-bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
}
.hover\:tw-text-gray-350:hover {
    --tw-text-opacity: 1;
    color: rgb(117 117 117 / var(--tw-text-opacity));
}
.hover\:tw-underline:hover {
    text-decoration-line: underline;
}
.hover\:tw-no-underline:hover {
    text-decoration-line: none;
}
.hover\:tw-opacity-100:hover {
    opacity: 1;
}
.focus\:tw-bg-black\/10:focus {
    background-color: rgb(0 0 0 / 0.1);
}
.tw-group:hover .group-hover\:tw-scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.tw-group:hover .group-hover\:tw-text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(64 64 64 / var(--tw-text-opacity));
}
.tw-group:hover .group-hover\:tw-text-salmon-400 {
    --tw-text-opacity: 1;
    color: rgb(224 125 92 / var(--tw-text-opacity));
}
.tw-group:hover .group-hover\:tw-underline {
    text-decoration-line: underline;
}
.tw-has-no-js .no-js\:tw-hidden {
    display: none;
}
@media (prefers-reduced-motion: no-preference) {
    .motion-safe\:tw-transition-all {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }
    .motion-safe\:tw-transition-transform {
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
    }
}
@media (min-width: 30em) {
    .xs\:tw-sticky {
        position: sticky;
    }
    .xs\:tw-left-auto {
        left: auto;
    }
    .xs\:tw-right-0 {
        right: 0px;
    }
    .xs\:tw-top-0 {
        top: 0px;
    }
    .xs\:tw-col-span-1 {
        grid-column: span 1 / span 1;
    }
    .xs\:tw-col-span-2 {
        grid-column: span 2 / span 2;
    }
    .xs\:tw-col-span-3 {
        grid-column: span 3 / span 3;
    }
    .xs\:tw-col-span-6 {
        grid-column: span 6 / span 6;
    }
    .xs\:tw-col-span-9 {
        grid-column: span 9 / span 9;
    }
    .xs\:tw-col-span-full {
        grid-column: 1 / -1;
    }
    .xs\:tw-col-start-4 {
        grid-column-start: 4;
    }
    .xs\:-tw-mt-1 {
        margin-top: -0.25rem;
    }
    .xs\:tw-mr-4 {
        margin-right: 1rem;
    }
    .xs\:tw-mt-10 {
        margin-top: 2.5rem;
    }
    .xs\:tw-grid {
        display: grid;
    }
    .xs\:tw-translate-x-0 {
        --tw-translate-x: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .xs\:tw-translate-x-\[75\%\] {
        --tw-translate-x: 75%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .xs\:tw-translate-y-0 {
        --tw-translate-y: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .xs\:tw-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .xs\:tw-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .xs\:tw-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .xs\:tw-flex-row {
        flex-direction: row;
    }
    .xs\:tw-gap-4 {
        gap: 1rem;
    }
    .xs\:tw-gap-legacy {
        gap: 34px;
    }
    .xs\:tw-gap-y-2 {
        row-gap: 0.5rem;
    }
    .xs\:tw-p-4 {
        padding: 1rem;
    }
    .xs\:tw-p-8 {
        padding: 2rem;
    }
    .xs\:tw-py-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .xs\:tw-text-right {
        text-align: right;
    }
    .xs\:tw-text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .xs\:tw-text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
@media (min-width: 60em) {
    .sm\:tw-sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }
    .sm\:tw-absolute {
        position: absolute;
    }
    .sm\:tw-left-auto {
        left: auto;
    }
    .sm\:tw-right-0 {
        right: 0px;
    }
    .sm\:tw-z-30 {
        z-index: 30;
    }
    .sm\:tw-order-2 {
        order: 2;
    }
    .sm\:tw-order-last {
        order: 9999;
    }
    .sm\:tw-col-span-3 {
        grid-column: span 3 / span 3;
    }
    .sm\:tw-col-span-4 {
        grid-column: span 4 / span 4;
    }
    .sm\:tw-col-span-5 {
        grid-column: span 5 / span 5;
    }
    .sm\:tw-col-span-6 {
        grid-column: span 6 / span 6;
    }
    .sm\:tw-col-span-8 {
        grid-column: span 8 / span 8;
    }
    .sm\:tw-col-start-3 {
        grid-column-start: 3;
    }
    .sm\:tw-mx-0 {
        margin-left: 0px;
        margin-right: 0px;
    }
    .sm\:tw-mb-12 {
        margin-bottom: 3rem;
    }
    .sm\:tw-mb-16 {
        margin-bottom: 4rem;
    }
    .sm\:tw-ml-8 {
        margin-left: 2rem;
    }
    .sm\:tw-mr-2 {
        margin-right: 0.5rem;
    }
    .sm\:tw-mt-4 {
        margin-top: 1rem;
    }
    .sm\:tw-block {
        display: block;
    }
    .sm\:tw-inline-block {
        display: inline-block;
    }
    .sm\:tw-grid {
        display: grid;
    }
    .sm\:tw-h-auto {
        height: auto;
    }
    .sm\:tw-w-2\/5 {
        width: 40%;
    }
    .sm\:tw-w-2\/6 {
        width: 33.333333%;
    }
    .sm\:tw-w-3\/4 {
        width: 75%;
    }
    .sm\:tw-w-3\/5 {
        width: 60%;
    }
    .sm\:tw-w-32 {
        width: 8rem;
    }
    .sm\:tw-w-40 {
        width: 10rem;
    }
    .sm\:tw-max-w-\[800px\] {
        max-width: 800px;
    }
    .sm\:tw-max-w-full {
        max-width: 100%;
    }
    .sm\:tw-translate-x-\[75\%\] {
        --tw-translate-x: 75%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .sm\:tw-translate-y-0 {
        --tw-translate-y: 0px;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .sm\:tw-scroll-mt-48 {
        scroll-margin-top: 12rem;
    }
    .sm\:tw-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    .sm\:tw-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .sm\:tw-flex-row {
        flex-direction: row;
    }
    .sm\:tw-flex-wrap {
        flex-wrap: wrap;
    }
    .sm\:tw-justify-normal {
        justify-content: normal;
    }
    .sm\:tw-justify-start {
        justify-content: flex-start;
    }
    .sm\:tw-gap-8 {
        gap: 2rem;
    }
    .sm\:tw-gap-legacy {
        gap: 34px;
    }
    .sm\:tw-gap-x-2 {
        -moz-column-gap: 0.5rem;
             column-gap: 0.5rem;
    }
    .sm\:tw-gap-x-8 {
        -moz-column-gap: 2rem;
             column-gap: 2rem;
    }
    .sm\:tw-space-y-12 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(3rem * var(--tw-space-y-reverse));
    }
    .sm\:tw-space-y-24 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(6rem * var(--tw-space-y-reverse));
    }
    .sm\:tw-rounded {
        border-radius: 0.25rem;
    }
    .sm\:tw-rounded-ss-none {
        border-start-start-radius: 0px;
    }
    .sm\:tw-bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }
    .sm\:tw-p-8 {
        padding: 2rem;
    }
    .sm\:tw-px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .sm\:tw-px-12 {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .sm\:tw-py-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .sm\:tw-py-1 {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .sm\:tw-py-2\.5 {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }
    .sm\:tw-py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .sm\:tw-pl-8 {
        padding-left: 2rem;
    }
    .sm\:tw-pt-16 {
        padding-top: 4rem;
    }
    .sm\:tw-text-left {
        text-align: left;
    }
    .sm\:tw-text-right {
        text-align: right;
    }
    .sm\:tw-text-start {
        text-align: start;
    }
    .sm\:tw-text-end {
        text-align: end;
    }
    .sm\:tw-text-2xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .sm\:tw-text-3xl\/snug {
        font-size: 1.875rem;
        line-height: 1.375;
    }
    .sm\:tw-text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
@media (min-width: 74.25em) {
    .md\:tw-col-span-6 {
        grid-column: span 6 / span 6;
    }
    .md\:tw-col-span-8 {
        grid-column: span 8 / span 8;
    }
    .md\:tw-col-start-3 {
        grid-column-start: 3;
    }
    .md\:tw-inline-block {
        display: inline-block;
    }
    .md\:tw-w-1\/2 {
        width: 50%;
    }
    .md\:tw-flex-row {
        flex-direction: row;
    }
    .md\:tw-flex-row-reverse {
        flex-direction: row-reverse;
    }
    .md\:tw-gap-x-5 {
        -moz-column-gap: 1.25rem;
             column-gap: 1.25rem;
    }
    .md\:tw-p-4 {
        padding: 1rem;
    }
}

