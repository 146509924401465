/* Custom Components */

/* Fluid embed iframes */
.responsive-embed {
    position: relative;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

/* Lazyloading images etc. */
.lazyload,
.lazyloading {
    transition: all 0.3s;
    opacity: 0.3;
    background: transparent no-repeat scroll center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 25%;
}
.lazyloaded {
    transition: all 0.3s;
    opacity: 1;
    background-image: none;
}



a.glossary-inline-link {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-thickness: 1.5px;
    text-decoration-style: dotted;
    text-decoration-color: hsl(15, 68%, 62%);
    cursor: help;
    transition: all 0.1s ease-in-out;
    outline: 0 solid hsla(15, 68%, 62%, 85%);
    &:hover {
        background-color: hsla(15, 68%, 62%, 85%);
        outline-width: 0.2em;
        text-decoration-color: hsla(15, 68%, 62%, 0%);
        border-radius: 2px;
    }
}



/* button internals */
.button-internal-icon {
  @apply tw-size-4 tw-stroke-2;
  &[data-position='end'] {
      @apply tw-order-last;
  }
}

/* Navigation */
.siteNav:hover button:not(:hover) {
  opacity: 0.7;
}
