@import 'base';
@import 'tailwindcss/base';
/* @import 'tailwindcss/components'; */
 @import 'components';
@import 'typography';
@import 'components/reel';
@import 'tailwindcss/utilities';
/* @import 'utilities'; */
@import 'photoswipe/dist/photoswipe.css';
@import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';
