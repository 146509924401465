/* 
    Reel Component
    Scroll content horizontally when overflowing.
    https://every-layout.dev/layouts/reel/
*/

.reel {
    --gutter: theme(space.2);
    --scrollbar-bg: transparent;
    --scrollbar-color: theme(colors.salmon.300);
    
    display: flex;
    block-size: auto;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg);
}
      
.reel::-webkit-scrollbar {
    block-size: 0.25rem;
}
      
.reel::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg);
}
      
.reel::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-bg);
    background-image: linear-gradient(
        var(--scrollbar-bg) 0,
        var(--scrollbar-bg) 0.25rem,
        var(--scrollbar-color) 0.25rem,
        var(--scrollbar-color) 0.75rem,
        var(--scrollbar-bg) 0.75rem);
}
      
.reel::after {
    content: '';
    flex-basis: theme(space.4);
    flex-shrink: 0;
}
  
.reel > * {
    flex: 0 0 auto;
}

.reel > * + * {
    margin-inline-start: var(--gutter);
}

.reel.overflowing {
    padding-block-end: var(--gutter);
}
.reel.reel-compact.overflowing {
    padding-block-end: 0;
}
