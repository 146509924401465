.rte {
    & > *:last-child {
        @apply tw-mb-0;
    }
    
    ol,
    ul {
        @apply tw-pl-4;
    }
    li:before {
        @apply -tw-ml-4 tw-w-4 tw-inline-block;
    }
    li + li {
        @apply tw-mt-2;
    }
    li li {
        @apply tw-ml-2;
    }
    ol li:before,
    ul[style] li:before{
        @apply tw-content-none;
    }
    
    blockquote {
        @apply 
            tw-ml-0 tw-pl-4 sm:tw-pl-6 sm:tw-mr-8 tw-mr-0
            tw-font-serif sm:tw-text-lg tw-text-gray-600 tw-italic
            tw-border-l-4 tw-border-gray-200;   
    }

    h3,
    h4,
    h5,
    h6 {
        @apply tw-leading-4 sm:tw-leading-snug;
    }

    h2 {
        @apply 
            tw-text-2xl sm:tw-text-3xl;
    }

    h3 {
        @apply 
            tw-text-xl sm:tw-text-2xl;
    }

    h4 {
      @apply 
          tw-text-base sm:tw-text-xl tw-uppercase;
    }

    h5 {
      @apply 
          tw-text-sm sm:tw-text-base tw-uppercase;
    }

    h6 {
      @apply 
          tw-text-xs sm:tw-text-sm tw-uppercase;
    }
    img {
      @apply 
          tw-h-auto tw-max-w-full;
    }
    figure {
      display: table;
      @apply tw-mx-auto tw-my-6;
    }
    figcaption p {
      @apply tw-m-0;
    }
}
.toc {
    li:before {
        @apply tw-pr-1 tw-text-salmon tw-absolute tw-left-6 tw-top-1;
    }
    a, a:visited {
        @apply tw-text-gray-350;
    }
}
.meta-m1 {
  @apply tw-uppercase tw-tracking-[0.3em];
}
.meta-m2 {
  @apply tw-uppercase tw-tracking-[0.1em];
}

/* Text Utilities */
.case-inherit {
  text-transform: inherit;
}

.text-align-inherit {
  text-align: inherit;
}

/* Heading Anchors */
:is(h2) a.anchor {
  @apply tw-absolute;
  width: 1.25em;
  height: 1.25em;
  margin-left: -1.75em;
  margin-left: 0.25em;
  @apply tw-bg-no-repeat tw-bg-center tw-rounded-full tw-opacity-0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244' /%3E%3C/svg%3E");
  background-size: 60% auto;
  &.copied {
      @apply tw-opacity-100;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3.5' stroke='green'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E");
  }
  &.copied.fadeOut {
      @apply tw-opacity-0;
  }
  &.copied::after {
      position: absolute;
      text-align: center;
      width: 150%;
      left: -25%;
      top: 115%;
      @apply sm:tw-top-1/2 sm:tw-left-full sm:tw-w-auto sm:-tw-translate-y-1/2 sm:tw-translate-x-[0.75em];
      opacity: 0.5;
      content: ' Copied';
      font-size: 0.5em;
  }
}
:is(h2):hover a.anchor {
  @apply tw-opacity-50;
}
:is(h2):hover a.anchor:hover {
  @apply tw-opacity-100;
  outline-color: rgba(0, 0, 0, 0.1);
  outline-width: 4px;
  text-decoration-color: rgba(51, 46, 25, 0);
  background-color: rgba(0, 0, 0, 0.1);
}
