/* Base */
html {
    scroll-behavior: smooth;
}

/* Defaults */
*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}

/* lazysizes auto width calculation */
picture img[data-sizes='auto'] {
    @apply tw-block tw-w-full;
}
picture img.w-auto[data-sizes='auto'] {
    width: auto;
}

/* Hide AlpineJS components until Alpine is ready */
html:not(.tw-has-no-js) [x-cloak] {
    display: none;
}
